import { ITranslationKeys } from '../ITranslationKeys'

export const en: ITranslationKeys = {
    alerts: 'Alerts',
    alert: {
        heading: 'Uh-oh! It seems there’s an issue.',
        generic:
            'Please refresh, or contact support if the problem continues. Thanks for your patience!',
        expiredSession: (count: number = 0) =>
            `It appears your session has expired. Redirecting in ${count} seconds...`,
        retry: 'Retry',
    },
    assetsProtection: {
        title: 'assets protection',
        tabs: {
            payroll: 'payroll',
            theft: 'theft',
            secure: 'secure',
        },
        secure: {
            title: 'security',
        },
    },
    indexRedirectMessage: 'Redirecting to login',
    locationSearch: {
        header: 'select location',
        searchLabel: 'search region, group, district, or store',
        matchNotFound:
            'no location matched your search. showing nearby locations.',
        nearbyLocationsHeader: 'nearby locations',
    },
    payroll: {
        title: 'payroll',
        tabs: {
            location: 'location',
            workgroup: 'work group',
            overtime: 'overtime',
            monthly: 'monthly',
        },
        location: {
            title: 'payroll by location',
            payrollByLocation: 'payroll by location',
            payrollByWeek: 'payroll by week',
            projFlexHours: 'proj flex hrs',
            guranteedFlexHours: 'guranteed flex hrs',
            bpFlextoplan: 'b/p flex to plan',
        },
        workgroup: {
            title: 'planned hours by work group',
        },
        overtime: {
            title: 'weekly overtime % of total hours - trend',
        },
    },
    safety: {
        title: 'safety',
        lagging: {
            title: 'lagging safety',
        },
        total: {
            title: 'total safety',
        },
        leading: {
            title: 'leading safety',
        },
        tabs: {
            lagging: 'lagging',
            total: 'total',
            leading: 'leading',
        },
        nonStoreLabel:
            'Data updates on Wednesdays and is good through Saturday',
        store: {
            'Lagging Indicators': 'lagging indicators',
            'Total Incidents': 'total incidents',
            'Leading Indicators': 'leading indicators',
        },
    },
    sales: {
        title: 'sales',
        tabs: {
            overview: 'overview',
            details: 'details',
            location: 'location',
        },
        overview: {
            hourlySales: 'hourly sales',
            originated: 'originated',
            lineBusting: 'line busting',
            fulfilled: 'fulfilled',
            shipFromStore: 'ship from store',
            driveUp: 'drive up',
            orderPickup: 'order pickup',
            shipt: 'shipt',
            otherSales: 'other sales',
            saveTheSale: 'save the sale',
            goal: 'goal',
            lastYear: 'last year',
            compPercent: 'comp %',
            originatedCompPercent: 'originated comp %',
            fulfilledCompPercent: 'fulfilled comp %',
            basketSize: 'basket size',
            transactions: 'transactions',
            total: 'total',
            ly: 'ly',
            bpToLy: 'b/p to ly',
            bpToLyPercent: 'b/p to ly %',
            goalPercentage: 'with a goal percentage of',
            of: 'of',
        },
        details: {
            workarea: {
                title: 'area of work',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
            merch: {
                title: 'sales by merch',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
        },
        location: {
            title: 'sales by location',
            tabs: {
                sales: 'sales',
                forecast: 'forecast',
                compPercent: 'comp %',
            },
        },
    },
    shortage: {
        title: 'shortage',
        totalShortage: 'total shortage',
        lastInvetoryDate: 'last inventory date',
        nextScheduledInventoryDate: 'next scheduled inventory date',
        'last inventory date': 'last inventory date',
        'next scheduled inventory date': 'next scheduled inventory date',
        thisYear: {
            title: 'this year',
        },
        merch: {
            title: 'shortage',
        },
        top10: {
            title: 'top 10 shortage',
        },
    },
    storeFacts: {
        title: 'store facts',
        quickFacts: 'quick facts',
        lastYearSales: 'last year sales',
        originatedSales: 'originated sales',
        fulfilledSales: 'fulfilled sales',
        totalSales: 'total sales',
        hours: 'hours',
        keyDates: 'key dates',
        opened: 'opened',
        lastRemodel: 'last remodeled',
        packStation: 'pack station',
        leadership: 'leadership',
        store: 'store',
        role: 'role',
        name: 'name',
        since: 'since',
        capabilities: 'capabilities',
    },
    settings: {
        title: 'settings',
        info: 'info',
        you: 'you',
        location: 'location',
        version: 'version',
        help: 'help',
        feedback: 'feedback',
        language: 'language',
        languages: {
            en: 'en',
            es: 'es',
            EN: 'EN',
            ES: 'ES',
        },
        creatingPreferences: 'creating user preferences',
        updatingPreferences: 'updating user preferences',
    },
    unauthorized: {
        title: 'Unauthorized',
        message:
            'You do not have access to view details inside MPM. If you need access reach out to myPerformance.support@target.com.',
    },
    contact: {
        mainPhone: 'main phone',
        fax: 'fax',
    },
    location: {
        region: 'region',
        group: 'group',
        district: 'district',
        RDC: 'RDC',
        county: 'County',
    },
    timePeriods: {
        today: 'today',
        yesterday: 'yesterday',
        weekToDate: 'week to date',
        lastWeek: 'last week',
        monthToDate: 'month to date',
        yearToDate: 'year to date',
    },
    filters: {
        compPercent: 'comp %',
        viewInDollars: 'view in $',
        viewInPercent: 'view in %',
    },
}
