import EnterpriseIcon, { ProcessingIcon } from '@enterprise-ui/icons'
import { InteractiveButton } from 'components/button'
import { Link } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { Link as LinkType, formatNegative, getNegativeStyle } from 'utils/data'

interface IBaseCell {
    value: string
}

interface ILinkCell extends IBaseCell {
    url: string
}

interface IButtonCell extends IBaseCell {
    link: LinkType
    is_link?: boolean
}

type Cell = IBaseCell | ILinkCell | IButtonCell

type RenderCellProps = {
    cell: Cell
    handleClick: ((value: string) => void) | undefined
    selectedCell: string
    isFooter?: boolean
    isLoading?: boolean
}

export const renderCell = ({
    cell,
    handleClick = undefined,
    selectedCell = '',
    isFooter = false,
    isLoading = false,
}: RenderCellProps) => {
    const formattedValue = formatNegative(cell.value)
    const formattedStyle = getNegativeStyle(cell.value)
    const cellToMatch = { ...cell, isFooter }
    const result = match(cellToMatch)
        .with({ url: P.select() }, (url) => (
            <InteractiveButton style={formattedStyle}>
                <Link to={url}>{formattedValue}</Link>
            </InteractiveButton>
        ))
        .with(P.union({ link: P.nonNullable }, { is_link: true }), () => {
            const onClickFn = () => {
                if (handleClick) {
                    handleClick(formattedValue || '')
                }
            }
            return (
                <InteractiveButton style={formattedStyle} onClick={onClickFn}>
                    {formattedValue}{' '}
                    {selectedCell === formattedValue && isLoading && (
                        <EnterpriseIcon
                            icon={ProcessingIcon}
                            className="toggle-loading-animation"
                            data-testid="processing-icon"
                        />
                    )}
                </InteractiveButton>
            )
        })
        .with({ isFooter: true }, () => (
            <strong className={formattedStyle}>{formattedValue}</strong>
        ))
        .otherwise(() => (
            <span className={formattedStyle}>{formattedValue}</span>
        ))

    return result
}
