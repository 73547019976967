import { Button, Heading } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import EnterpriseIcon, {
    CancelIcon,
    ChevronLeftIcon,
} from '@enterprise-ui/icons'
import { useState } from 'react'
import { Divider, Form, Input } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

import { UserDetailsData } from '../../queries/getUserDetailsQuery'
import { useMPMEnv } from '../../utils/env'

import './location-search.css'
import { useUser } from '../../components/user-context'
import { useNearbyLocations } from 'components/nearby-locations'
import { useUserDetails } from 'hooks/userDetails/useUserDetails'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { t } from 'i18next'

const navigateBack = -1

const BackButton = () => {
    const navigate = useNavigate()

    return (
        <Button
            iconOnly
            className="navbar-back-button"
            aria-label="Go back to the previous screen"
            type="ghost"
            onClick={() => navigate(navigateBack)}
        >
            <EnterpriseIcon
                icon={ChevronLeftIcon}
                size="xl"
                className="navbar-icon"
            />
        </Button>
    )
}

interface Location {
    id: number | string
    name: string
    shortName: string
}

const getLocationsMatchingSearchTerm = (
    rawLocationData: UserDetailsData['locations'] | undefined,
    searchQuery: string,
): Location[] => {
    if (searchQuery.trim().length === 0 || rawLocationData === undefined) {
        return []
    }

    const locationsMatchingSearchTerm = Object.values(rawLocationData)
        .flat()
        .map((rawLocation) => ({
            id: rawLocation.id,
            name: rawLocation.name,
            shortName: rawLocation.short_name,
        }))
        .filter(
            (location) =>
                `${location.id}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                location.shortName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                location.name.toLowerCase().includes(searchQuery.toLowerCase()),
        )

    return locationsMatchingSearchTerm
}

interface LocationSearchResultProps {
    locationId: string | number
    locationShortName: string
    locationName: string
    distanceAway?: number
}

export const LocationSearchResult = ({
    locationId,
    locationShortName,
    locationName,
    distanceAway,
}: LocationSearchResultProps) => {
    const { setLocation } = useUser()
    const navigate = useNavigate()
    const {
        state: { previousLocationPathname },
    } = useLocation()
    const locationSearchResultOnClickHandler = () => {
        setLocation({
            locationId: locationId.toString(),
            shortName: locationShortName,
            name: locationName,
        })

        const newPath =
            previousLocationPathname + '?locationId=' + locationShortName
        navigate(newPath)
    }

    return (
        <div
            className="location-search-result"
            role="button"
            onClick={locationSearchResultOnClickHandler}
        >
            <div>
                <div className="location-search-result-short-name">
                    {locationShortName}
                </div>
                <div>{locationName}</div>
            </div>
            {distanceAway && (
                <div className="location-search-result-distance-away">{`${distanceAway.toFixed(
                    2,
                )} mi`}</div>
            )}
        </div>
    )
}

interface LocationMatchNotFoundProps {
    isLoading?: boolean
    locationSearchResults?: Location[]
    searchTerm: string
}

export const LocationMatchNotFound = ({
    isLoading,
    locationSearchResults,
    searchTerm,
}: LocationMatchNotFoundProps) => {
    const { locationSearch } = TRANSLATION_KEYS
    return (
        <span>
            {!isLoading &&
                searchTerm &&
                locationSearchResults?.length === 0 && (
                    <span className="hc-clr-alert" aria-live="polite">
                        {t(locationSearch.matchNotFound)}
                    </span>
                )}
        </span>
    )
}

export const LocationSearchPage = () => {
    const { locationSearch } = TRANSLATION_KEYS
    const [searchQuery, setSearchQuery] = useState('')
    const { session } = useAuth()
    const lanId = session?.userInfo?.lanId ?? ''
    const env = useMPMEnv()
    const { nearbyLocations } = useNearbyLocations()
    const { data, isLoading } = useUserDetails({
        env,
        accessToken: session?.accessToken ?? '',
        lanId,
    })
    const locationSearchResults = getLocationsMatchingSearchTerm(
        data?.locations,
        searchQuery,
    )

    return (
        <>
            <div className="location-search-fixed-content">
                <div className="location-search-navbar-container">
                    <BackButton />
                    <Heading className="location-search-navbar-heading">
                        {t(locationSearch.header)}
                    </Heading>
                </div>
                <div className="location-search-fixed-input">
                    <Input.Label htmlFor="location-search-input">
                        {t(locationSearch.searchLabel)}
                    </Input.Label>
                    <div className="location-search-input-container">
                        <Form.Field
                            id="location-search-input"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setSearchQuery(event.target.value)
                            }}
                            className="location-search-input"
                            value={searchQuery}
                        />
                        <Button
                            iconOnly
                            id="location-search-input-clear-button"
                            aria-label="Clear location search query"
                            onClick={() => setSearchQuery('')}
                            type="ghost"
                        >
                            <EnterpriseIcon icon={CancelIcon} size="lg" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="location-search-content-container">
                <LocationMatchNotFound
                    isLoading={isLoading}
                    locationSearchResults={locationSearchResults}
                    searchTerm={searchQuery}
                />
                {(!searchQuery || locationSearchResults.length === 0) && (
                    <>
                        {!searchQuery && (
                            <h2 className="nearby-locations-heading">
                                {t(locationSearch.nearbyLocationsHeader)}
                            </h2>
                        )}
                        {nearbyLocations.map(
                            (nearbyLocation, nearbyLocationIndex) => (
                                <div
                                    key={`nearby-location-${nearbyLocation.location_id}-${nearbyLocation.location_names[0].name}`}
                                >
                                    <LocationSearchResult
                                        locationId={nearbyLocation.location_id}
                                        locationShortName={`T${nearbyLocation.location_id
                                            .toString()
                                            .padStart(4, '0')}`}
                                        locationName={
                                            nearbyLocation.location_names[0]
                                                .name
                                        }
                                        distanceAway={nearbyLocation.distance}
                                    />
                                    {nearbyLocationIndex !==
                                        nearbyLocations.length - 1 && (
                                        <Divider />
                                    )}
                                </div>
                            ),
                        )}
                    </>
                )}
                {!isLoading &&
                    locationSearchResults.map((location, locationIndex) => (
                        <div key={`${location.name}-${location.shortName}`}>
                            <LocationSearchResult
                                locationId={location.id}
                                locationShortName={location.shortName}
                                locationName={location.name}
                            />
                            {locationIndex !==
                                locationSearchResults.length - 1 && <Divider />}
                        </div>
                    ))}
            </div>
        </>
    )
}
