import { renderCell } from 'components/cell'
import { isStore } from './location'

export interface Link {
    href?: string
}

export interface Cell {
    raw?: string
    value: string
    link?: Link
    url?: string
    childOf?: string
    is_link?: boolean
}

export interface Row {
    rowCells: Cell[]
    children?: {
        rows: {
            rowCells: Cell[]
        }[]
    }
}

export interface TableData {
    headings?: Cell[]
    rows: Cell[][]
    footer?: Cell[]
}

export interface TitledTableData {
    title: string
    table: TableData
}

export const formatNegative = (
    rowCellValue: string,
    rowCellIndex?: number,
): string | null => {
    let formattedValue = rowCellValue
    if (rowCellValue?.startsWith('-') && rowCellIndex !== 0) {
        formattedValue = '(' + rowCellValue.slice(1) + ')'
    }
    return formattedValue
}
export const getNegativeStyle = (value: string): string => {
    let fontColor: any
    if (value?.startsWith('-')) {
        fontColor = 'hc-clr-error'
        return fontColor
    }
    return ''
}
export const getMultipleSlices = (
    array: Cell[],
    ...args: (number | [number, number])[]
): Cell[] =>
    args.flatMap((arg) => {
        if (typeof arg === 'number') {
            return array.slice(arg)
        }
        return array.slice(arg[0], arg[1])
    })
export const addLinkToFirstColumn = (arr: Cell[][]) =>
    [...arr].map((row) => {
        row[0].link = {}

        return row
    })
export const removeLinkFromFirstColumn = (arr: Cell[][]) =>
    [...arr].map((row) => {
        row[0].link = undefined

        return row
    })

export const addHyperlinkToFirstColumn = (
    data: TableData,
    currentLocation: string,
    pathname?: string,
) => {
    if (data) {
        const { rows } = data
        if (rows) {
            data.rows = [...rows]?.map((row) => {
                const locationCell = row[0]
                if (!isStore(currentLocation)) {
                    locationCell.url =
                        (pathname ?? '') +
                        '?locationId=' +
                        (locationCell.value || '')
                }
                return row
            })
        }
    }
    return data
}

export const insertRows = (arr: Cell[][], index: number, items: Cell[][]) =>
    arr.splice(index, 0, ...items)

export type DataTableObjectType = {
    currentData: TableData
    widths: number[]
    handleClick?: (value: string) => void
    pinFirstColumn?: boolean
    bottomLeftCell?: string
    selectedCell?: string
    sortable?: boolean
    isLoading?: boolean
}
type FooterObjectType = {
    [key: string]:
        | string
        | {
              cellDisplay: React.ReactNode
              cellValue: string
          }
}
export const buildDataTableObject = ({
    currentData,
    widths,
    handleClick,
    pinFirstColumn,
    bottomLeftCell,
    selectedCell = '',
    sortable = false,
    isLoading,
}: DataTableObjectType) => {
    const getCellDisplay = (field: Cell) => {
        if (!field) return
        let cellObj: { [key: string]: string | React.ReactNode } = {
            cellValue: field?.value,
            cellDisplay: renderCell({
                cell: field,
                handleClick,
                selectedCell,
                isLoading,
            }),
            isLink: field?.is_link,
        }

        return cellObj
    }
    const rowsObj = currentData?.rows?.map((row) => {
        const rowObj = {} as any
        currentData?.headings?.forEach(
            (heading: Cell, headingIndex: number) => {
                const key = heading.value.replaceAll(' ', '')
                const field = row[headingIndex]
                rowObj[key] = getCellDisplay(field)
            },
        )

        return rowObj
    })
    const footerObj = {} as FooterObjectType
    if (bottomLeftCell) {
        currentData?.footer?.unshift({ value: bottomLeftCell })
    }
    currentData?.footer?.forEach((footer, index) => {
        const key = currentData?.headings?.[index]?.value.replaceAll(
            ' ',
            '',
        ) as string
        const value = formatNegative(footer.value) as string
        footerObj[key] = {
            cellDisplay: renderCell({
                cell: footer,
                handleClick,
                isFooter: true,
                selectedCell,
            }),
            cellValue: value,
        }
    })
    if (Object.keys(footerObj).length > 0) {
        rowsObj.push(footerObj)
    }
    const obj = {
        defaultColDef: {
            sortable,
        },
        columnDefs: currentData?.headings?.map((heading, index) => ({
            field: heading.value.replaceAll(' ', ''),
            headerName: heading.value,
            isRowHeader: index === 0 ? true : false,
            resizable: true,
            width: widths[index],
            align: index === 0 ? 'left' : 'right',
            pinned: pinFirstColumn && index === 0 ? true : false,
        })),
        rowData: rowsObj,
    }

    return obj
}
